import React from 'react';
import Layout from '../components/Layout/layout';
import Contact from '../components/Contact/contact'
import SEO from '../components/seo'

function contactPage() {
  return (
        <Layout>
            <SEO
                title="Contact"
            />
            <Contact/>            
        </Layout>
    )
}

export default contactPage;