import React from 'react';
import  { useStaticQuery, graphql} from 'gatsby'
import Img  from 'gatsby-image';

import './contact.css'

function Contact() {
  const data = useStaticQuery(graphql`
  query {
    contactImage: file(relativePath: {eq: "art-artistic-blur-brown.jpg"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
  `)

  return (
      <section className="contact">
          <div className="container-contact">
            <div className="contact-box">
              <h2>Vendedores</h2>
              <div className="contact-sellers">
                <div>
                  <h4>José Jordão</h4>
                  <h4>Jordão Victor</h4>
                </div>
                <div>
                  <h4>(48) 99969-4060</h4>
                  <h4>(48) 99855-9500</h4>
                </div>
              </div>
              <h2>Fale conosco</h2>
              <div className="contact-info">
               
                <h4>Email: contato@blendcerto.com.br</h4>
                <h4>Telefone: (48)3094-6797</h4>
              
              </div>
            </div>
            <Img className="contact-image"fixed={data.contactImage.childImageSharp.fixed} alt="Coffee"/> 
          </div>
      </section>
  )
}

export default Contact;